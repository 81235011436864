@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons";

body {
    margin: 0;
    padding: 0;
}

.col {
    padding: 0;
}

.dashboard-wrapper {
    background-color: $background-color-primary;
    min-height: 100vh;
}

.dashboard-header {
    padding: 20px;
    background-color: white;
    display: flex;
    align-items: center;

    img {
        height: 50px;
    }
}

.rounded-top-left {
    border-top-left-radius: 5px;
}

.user-list-wrapper {
    margin: 20px 0;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &__header {
        display: flex;
        justify-content: center;
        padding: 15px 15px 0 15px;

        h2 {
            font-size: 18px;
            font-weight: 600;
            border: 1px solid #e9e9e9;
            text-align: center;
            padding: 10px 0;
            cursor: pointer;

            span {
                i {
                    color: #000;
                    margin-left: 2px;
                }
            }

            &:first-child {
                flex: 0.5;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                background-color: #e9e9e9;
            }

            &:nth-child(2) {
                flex: 1;
            }

            &:nth-child(3) {
                flex: 1;
            }

            &:nth-child(4) {
                flex: 1;
            }

            &:nth-child(5) {
                flex: 1;
            }

            &:nth-child(6) {
                flex: 1;
            }

            &:nth-child(7) {
                flex: 1;
            }

            &:last-child {
                flex: 1;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    &__list {
        padding: 0 15px;

        &__item {
            list-style: none;
            display: flex;

            &:hover {
                box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
                    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                border-radius: 5px;
                cursor: pointer;

                span {
                    border: none;
                }
            }

            &:last-child {
                span {
                    border-bottom: none;
                }
            }

            span {
                padding: 10px 0;
                border-bottom: 1px solid #e9e9e9;
            }

            &__id {
                flex: 0.5;
                justify-content: center;
                display: flex;
                align-items: center;
            }

            &__data {
                flex: 1;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                min-width: 0;
            }

            // &__actions {
            //     flex: 1;
            //     display: flex;
            //     justify-content: center;

            //     i {
            //         background-color: #e9e9e9;
            //         border-radius: 50%;
            //         margin-right: 10px;
            //         width: 40px;
            //         height: 40px;
            //         display: flex;
            //         align-items: center;
            //         justify-content: center;
            //         cursor: pointer;

            //         &:hover {
            //             background-color: $background-color-primary;
            //             color: white;
            //         }

            //         &:last-child:hover {
            //             background-color: red;
            //         }
            //     }
            // }
        }

        .empty-list {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 50px;
            font-size: 26px;
            font-weight: 600;

            p {
                margin-bottom: 0;
                margin-left: 10px;
            }
        }
    }
}

.sidebar {
    width: 100%;
    margin-top: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

    &__voice-list {
        list-style: none;
        display: flex;
        width: 100%;
        margin-bottom: 0;
        padding-left: 0;

        &__item {
            cursor: pointer;
            flex: 1;
            padding: 20px;

            &:hover {
                .sidebar__voice-list__item__icon-wrapper {
                    text-decoration: underline;
                }
            }

            &__icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                color: black;

                p {
                    margin: 0;
                    padding-left: 10px;
                    font-size: 16px;
                }

                i {
                    font-size: 20px;
                }
            }

            &--active {
                flex: 1;

                &:first-child {
                    .sidebar__voice-list__item__icon-wrapper {
                        margin-left: 4px;
                    }
                }

                &:nth-child(2) {
                    .sidebar__voice-list__item__icon-wrapper {
                        white-space: nowrap;
                    }
                }

                &:last-child {
                    .sidebar__voice-list__item__icon-wrapper {
                        margin-right: 4px;
                    }
                }
                .sidebar__voice-list__item__icon-wrapper {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
                        rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                    transform: translateY(4px);
                    padding: 16px;
                    border-radius: 5px;
                }
            }
        }
    }
}

.form-wrapper {
    padding: 15px 20px;

    &__header {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 10px;
    }

    &__form {
        margin-top: 40px;

        &__label {
            margin-top: 20px;
            margin-bottom: 10px;

            &:first-child {
                margin-top: 0;
            }
        }

        .invalid-field {
            border: 1px solid;
            margin-bottom: 5px;
            color: #842029;
            background-color: #f8d7da;
            border-color: #f5c2c7;

            & + p {
                color: #842029;
                font-size: 14px;
            }
        }

        .valid-field {
            border: 2px solid;
            margin-bottom: 5px;
            color: #0f5132;
            background-color: #d1e7dd;
            border-color: #badbcc;
        }

        .form-button {
            width: 150px;

            &:first-child {
                margin-right: 10px;
            }

            &:last-child {
                margin-left: 10px;
            }
        }
    }
}

.logo-wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        width: 300px;
        height: 300px;
        margin-top: 30px;
    }
}

.login-wrapper {
    position: relative;
    background-color: white;
    flex-direction: column;
    border-radius: 5px;
    height: 100%;
    padding: 30px;
    box-shadow: 0 5px 10px (rgb(27, 27, 27));

    &__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        img {
            width: 70%;
        }
    }

    &__header {
        font-size: 24px;
        font-weight: 600;
    }

    &__form {
        margin-top: 30px;

        &__button {
            margin-top: 50px;
            background-color: $background-color-primary;
            border: none;
        }
    }
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-border {
    height: 50px;
    width: 50px;
}

.user-details {
    padding: 30px;

    &__header {
        display: flex;
        background-color: $background-color-primary;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        padding: 30px 20px;
        color: white;

        h2 {
            font-size: 24px;
            font-weight: 600;
            margin-left: 10px;
            margin-bottom: 0;
        }

        i {
            font-size: 30px;
        }
    }

    &__list {
        list-style: none;
        display: flex;
        margin-top: 50px;
        padding: 0;
        width: 100%;
        margin-bottom: 0;
        flex-direction: column;

        &__item {
            width: 100%;
            display: flex;
            border-bottom: 1px solid #e9e9e9;
            margin-bottom: 20px;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }

            &__field {
                width: 50%;

                p {
                    margin: 10px 0 10px 32px;
                    font-size: 16px;
                }

                &__label {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 22px;
                        margin-right: 10px;
                        color: $background-color-primary;
                    }
                }
            }
        }
    }
}

.filters-wrapper {
    background-color: $background-color-primary;
    margin-top: 15px;
    padding: 20px 10px;
    border-radius: 5px;
    justify-content: space-between;
    color: white;
    box-shadow: 0 0 5px #000 inset;
}
